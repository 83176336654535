@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

.user-avatar {
  margin: 2%;
  display: flex;
  justify-content: space-between;
}

.user-avatar-name-img {
  display: flex;
  gap: 1em;
  padding-left: 50px;
  padding-top: 50px;
  /* justify-content: space-between; */
}

.user-avatar-img {
  width: 100px;
  border: 1px;
  border-color: #3a86ff;
  border-radius: 50%;
  box-shadow: 1px 6px 5px #3a86ff;
}

.user-avatar-name {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Roboto Slab", serif;
  font-size: 25px;
  font-weight: 600;
}

.user-avatar-logo {
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  /* width: 100px; */
  height: 100px;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 5px 5px 6px 5px #ccc;
}
