.show-btns {
  margin-left: 50px;
}

.show-btn {
  position: relative;
  display: inline-block;
  margin: 15px;
  padding: 15px 30px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  color: white;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid white;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #ff3a69;
}

.show-btn:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #ff3a69;
}

.show-btn:active {
  transform: scale(0.9);
}

.show-cv-btn {
  background-color: #3a86ff;
}
.show-documents-btn {
  background-color: #8ad153;
}
